import LockitronLogo from '_images/svgs/lockitron-logo.inline.svg'
import SULogo from '_images/svgs/su-logo.inline.svg'
import SkyroamLogo from '_images/svgs/skyroam-logo.inline.svg'
import TapcartLogo from '_images/svgs/tapcart-logo.inline.svg'
import FindHeliLogo from '_images/svgs/findheli-logo.inline.svg'
import variables from '_config/css-variables'

export const getCaseStudies = intl => [
  {
    name: 'lockitron',
    color: '#383A3A',
    link: '/portfolio/lockitron/',
    children: [
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.lockitron.text1' }) },
      { type: 'image', ImageComponent: LockitronLogo },
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.lockitron.text2' }) },
    ],
  },
  {
    name: 'su',
    color: '#0668AD',
    link: '/portfolio/singularity-university/',
    children: [
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.su.text1' }) },
      { type: 'image', ImageComponent: SULogo },
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.su.text2' }) },
    ],
  },
  {
    name: 'skyroam',
    color: '#F54B2C',
    isFullWidth: false,
    isBigImage: true,
    link: '/portfolio/skyroam/',
    children: [
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.skyroam.text1' }) },
      { type: 'image', ImageComponent: SkyroamLogo },
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.skyroam.text2' }) },
    ],
  },
  {
    name: 'tapcart',
    color: '#363EA9',
    link: '/portfolio/tapcart/',
    children: [
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.tapcart.text1' }) },
      { type: 'image', ImageComponent: TapcartLogo },
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.tapcart.text2' }) },
    ],
  },
  {
    name: 'findheli',
    color: variables['findheli-blue-secondary'],
    link: '/portfolio/findheli/',
    children: [
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.findheli.text1' }) },
      { type: 'image', ImageComponent: FindHeliLogo },
      { type: 'text', content: intl.formatMessage({ id: 'caseStudiesInfo.findheli.text2' }) },
    ],
  },
]

export default getCaseStudies
