import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames/bind'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Fade from 'react-reveal/Fade'

import { getCaseStudies } from '_config/case-studies-home'
import { FOUNDED_BRANDS, FEATURED_BRANDS } from '_config/home-brands'
import { Container, Heading, Text, BlueText, Button } from '_atoms'
import { Hero, ServiceIcons, BrandsFixed, BrandsFluid, QuoteContainer } from '_molecules'
import { Layout } from '_templates'
import { SEO, CaseSliderItem, ShowProjectByBrand, ViewportObserver } from '_organisms'
import { AppContext } from '_context'
import { useWindowSize } from '_hooks'
import SULogo from '_images/svgs/su-logo.inline.svg'
import SodexoLogo from '_images/svgs/sodexo-logo.svg'
import AESLogo from '_images/svgs/aes-logo.inline.svg'
import TapcartLogo from '_images/svgs/tapcart-logo.inline.svg'
import MercadoLibreLogo from '_images/svgs/mercado-libre-logo.inline.svg'
import MercadoLivreLogo from '_images/svgs/mercado-livre-logo.inline.svg'
import MerckLogo from '_images/svgs/merck-logo.svg'
import variables from '_config/css-variables'

import styles from './styles.module.css'
import './overrides.css'

const cx = classNames.bind(styles)

const IndexPage = ({ data }) => {
  const [width] = useWindowSize()
  const isMobile = width <= 768

  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const { homeTitle, homeDescription, homeThumbnail } = data.contentfulPageMetatags
  const allQuotes = data.allContentfulQuote.nodes
  const lockitronCasePicture =
    data[isMobile ? 'lockitronMobile' : 'lockitron'].childImageSharp.fluid
  const suCasePicture = data[isMobile ? 'suMobile' : 'su'].childImageSharp.fluid
  const skyroamCasePicture = data[isMobile ? 'skyroamMobile' : 'skyroam'].childImageSharp.fluid
  const tapcartCasePicture = data[isMobile ? 'tapcartMobile' : 'tapcart'].childImageSharp.fluid
  const findheliCasePicture = data[isMobile ? 'findheliMobile' : 'findheli'].childImageSharp.fluid

  /* scroll back to top when scrolling on Home, going to another page and coming back */
  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])

  const intl = useIntl()
  const CASE_STUDIES = getCaseStudies(intl)
  const isPtLocale = intl.locale === 'pt'
  const CASE_IMAGES = {
    lockitron: {
      image: lockitronCasePicture,
      alt: 'Lockitron',
      maxWidth: 552,
    },
    su: {
      image: suCasePicture,
      alt: 'Singularity University',
      maxWidth: 511,
    },
    skyroam: {
      image: skyroamCasePicture,
      alt: 'Skyroam',
      maxWidth: 1075,
    },
    tapcart: {
      image: tapcartCasePicture,
      alt: 'Tapcart',
      maxWidth: 421,
    },
    findheli: {
      image: findheliCasePicture,
      alt: 'Findheli',
      maxWidth: 599,
    },
  }

  const customHeroContent = (
    <Row className={styles.heroContent}>
      <Col xs={12} sm={12} md={7} lg={7}>
        <Fade distance="25%" top>
          <Heading type="h1" color="white" bold className={styles.heroTitle}>
            <FormattedMessage id="home.hero.title" />
            <BlueText light squared>
              .
            </BlueText>
          </Heading>
        </Fade>
        <Fade distance="25%" bottom>
          <Heading type="h2" color="white" className={styles.heroSubtitle}>
            <FormattedMessage id="home.hero.description" />
          </Heading>
        </Fade>
      </Col>
      <Col xs={12} sm={12} md={5} lg={5} className={styles.heroLogos}>
        <div className={styles.heroLogosDesktop}>
          <Fade distance="25%" delay={300} top>
            <SULogo />
            <TapcartLogo className={styles.tapcart} />
            {isPtLocale ? (
              <MercadoLivreLogo className={styles.mercadoLibre} />
            ) : (
              <MercadoLibreLogo className={styles.mercadoLibre} />
            )}
            <AESLogo className={styles.aes} />
            <SodexoLogo className={styles.sodexo} />
            <MerckLogo className={styles.merck} />
          </Fade>
        </div>

        <div className={styles.heroLogosMobile}>
          <Fade distance="25%" delay={300} bottom>
            <SULogo />
            <TapcartLogo className={styles.tapcart} />
            {isPtLocale ? (
              <MercadoLivreLogo className={styles.mercadoLibre} />
            ) : (
              <MercadoLibreLogo className={styles.mercadoLibre} />
            )}
            <AESLogo className={styles.aes} />
          </Fade>
        </div>
      </Col>
    </Row>
  )

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    500
  )

  const onScrollArrowClick = () =>
    typeof window !== 'undefined' &&
    document.getElementById(CASE_STUDIES[0].name).scrollIntoView({ behavior: 'smooth' })

  return (
    <Layout>
      <SEO
        title={homeTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={homeDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={homeThumbnail.file.url}
      />

      <main className={cx('heroBackground')}>
        <Hero
          hasScrollArrow
          scrollArrowText={intl.formatMessage({ id: 'home.hero.scrollArrowText' })}
          className={styles.hero}
          contentClassName={styles.heroContent}
          scrollArrowButton
          onScrollArrowClick={() => onScrollArrowClick()}
          customContent={customHeroContent}
        />

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-primary'])
            setNavbarLight(false)
          }}
        />
      </main>

      {CASE_STUDIES.map(caseStudy => (
        <CaseSliderItem
          key={caseStudy.name}
          name={caseStudy.name}
          title={caseStudy.children}
          color={caseStudy.color}
          link={caseStudy.link}
          linkId={caseStudy.linkId}
          fullwidth={caseStudy.isFullWidth}
          scroll={y}
          onViewport={() => {
            setNavbarColor(caseStudy.color)
            setNavbarLight(false)
          }}
        >
          <CaseSliderItem.Img
            fluid={CASE_IMAGES[caseStudy.name].image}
            alt={CASE_IMAGES[caseStudy.name].alt}
            maxWidth={CASE_IMAGES[caseStudy.name].maxWidth}
          />
        </CaseSliderItem>
      ))}

      <div className={cx('section', 'safariScrollFlickerFix')}>
        <section className={cx('sub-section', 'blueSection', 'safariScrollFlickerFix')}>
          <Container className={cx('safariScrollFlickerFix', 'blueSectionContainer')}>
            <Text className={styles.brandsSubtitle} align="center" size="24" color="white">
              <strong>
                <FormattedMessage id="home.ourWork.text1" />
              </strong>{' '}
              <FormattedMessage id="home.ourWork.text2" />
            </Text>
            <div className={styles.brandsRow}>
              {FEATURED_BRANDS.map(brand => (
                <ShowProjectByBrand
                  key={`featured-brand-${brand.name}`}
                  brandClassName={styles.pressBrands}
                  projectClassName={styles.projectBrands}
                  projects={brand.projects}
                  brand={brand.name}
                />
              ))}
            </div>
          </Container>

          <Container className={styles.safariScrollFlickerFix}>
            <Text className={styles.brandsSubtitle} align="center" size="24" color="white">
              <FormattedMessage id="home.ourProjects.text1" />
              <strong>
                {' '}
                <FormattedMessage id="home.ourProjects.text2" />{' '}
              </strong>
              <FormattedMessage id="home.ourProjects.text3" />
            </Text>
            <div className={cx('foundedByRow', 'foundedByRowDesktopVersion')}>
              {FOUNDED_BRANDS.map(brand => (
                <a
                  className={styles.foundedByBrands}
                  key={`founded-by-brand-${brand.name}`}
                  href={brand.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BrandsFixed name={brand.name} />
                </a>
              ))}
            </div>
            <div className={cx('foundedByRow', 'foundedByRowMobileVersion')}>
              {FOUNDED_BRANDS.map(brand => (
                <a
                  className={styles.foundedByBrands}
                  key={`founded-by-brand-${brand.name}`}
                  href={brand.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <BrandsFluid name={brand.name} />
                </a>
              ))}
            </div>
          </Container>
          <ViewportObserver
            scroll={y}
            customViewport={{ top: 90, bottom: -200 }}
            onViewport={() => {
              setNavbarColor(variables['color-primary'])
              setNavbarLight(false)
            }}
          />
        </section>

        <section className={cx('sub-section', 'whatSetUsApartSection', 'safariScrollFlickerFix')}>
          <div className={styles.whatSetUsApartSectionBlock}>
            <div className={styles.whatSetUsApartSectionBlockText}>
              <Container>
                <Heading color="white" className={styles.whatSetUsApartTitle} type="h4">
                  <FormattedMessage id="home.whatSetUsApart" />
                  <BlueText squared> ?</BlueText>
                </Heading>
              </Container>

              <QuoteContainer
                items={allQuotes}
                isWhite
                isCenter
                className={styles.whatSetUsApartQuote}
                sliderClass={styles.whatSetUsApartQuoteSlider}
                iconsClass={styles.whatSetUsApartQuoteIcons}
                hideUserPhotoMobile
              />

              <ViewportObserver
                scroll={y}
                customViewport={{ top: 90, bottom: -200 }}
                onViewport={() => {
                  setNavbarColor(variables['color-dark-bg'])
                  setNavbarLight(false)
                }}
              />
            </div>
          </div>
        </section>

        <section className={cx('sub-section', 'whatWeDoSection', 'safariScrollFlickerFix')}>
          <Container>
            <div className={styles.whatWeDoContent}>
              <Row gutter={40}>
                <Col xs={12} sm={12} md={5} lg={4}>
                  <Heading className={styles.whatWeDoTitle} type="h3">
                    <span className={styles.whatWeDoBreak}>
                      <FormattedMessage id="home.whatWeDo.firstWord" />{' '}
                    </span>
                    <FormattedMessage id="home.whatWeDo.title" />
                    <strong>
                      {' '}
                      <FormattedMessage id="home.whatWeDo.titleLastWord" />
                      <span className={styles.squared}>?</span>
                    </strong>
                  </Heading>
                  <Button className={styles.whatWeDoDesktopButton} href="/services">
                    <FormattedMessage id="home.whatWeDo.button" />
                  </Button>
                </Col>

                <Col xs={12} sm={12} md={7} lg={8}>
                  <div className={styles.textCol}>
                    <FormattedMessage
                      id="home.whatWeDo.description"
                      values={{ p: (...chunks) => <Text size="24">{chunks}</Text> }}
                    />
                    <Row gutter={40}>
                      <Col xs={6} lg={3}>
                        <ServiceIcons name="uiux-design-icon" />
                        <Text align="center" size="20">
                          <FormattedMessage id="home.whatWeDo.product" />
                        </Text>
                      </Col>
                      <Col xs={6} lg={3}>
                        <ServiceIcons name="mobile-dev-icon" />
                        <Text align="center" size="20">
                          <FormattedMessage id="home.whatWeDo.design" />
                        </Text>
                      </Col>
                      <Col xs={6} lg={3}>
                        <ServiceIcons name="web-dev-icon" />
                        <Text align="center" size="20">
                          <FormattedMessage id="home.whatWeDo.app" />
                        </Text>
                      </Col>
                      <Col xs={6} lg={3}>
                        <ServiceIcons name="nextgen-icon" />
                        <Text align="center" size="20">
                          <FormattedMessage id="home.whatWeDo.nextgen" />
                        </Text>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div className={styles.whatWeDoMobileButtonContainer}>
                <Button className={styles.whatWeDoMobileButton} href="/services">
                  <FormattedMessage id="home.whatWeDo.button" />
                </Button>
              </div>
            </div>
          </Container>
          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['color-white'])
              setNavbarLight(true)
            }}
          />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuotes($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      homeTitle
      homeDescription
      homeThumbnail {
        file {
          url
        }
      }
    }
    allContentfulQuote(
      filter: {
        quoteCategory: { elemMatch: { name: { eq: "Home" } } }
        fields: { localeKey: { eq: $locale } }
      }
    ) {
      nodes {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
    lockitron: file(relativePath: { eq: "lockitron-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    lockitronMobile: file(relativePath: { eq: "lockitron-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    su: file(relativePath: { eq: "su-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 511) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    suMobile: file(relativePath: { eq: "su-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    skyroam: file(relativePath: { eq: "skyroam-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 731) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    skyroamMobile: file(relativePath: { eq: "skyroam-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tapcart: file(relativePath: { eq: "tapcart-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 421) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tapcartMobile: file(relativePath: { eq: "tapcart-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    findheli: file(relativePath: { eq: "findheli-cover.png" }) {
      childImageSharp {
        fluid(maxWidth: 599) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    findheliMobile: file(relativePath: { eq: "findheli-cover-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

IndexPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default IndexPage
